<template>
  <div class="">
    <Settings />
    <div class="px-4 md:px-8 xl:px-24 xxl:max-w-screen-xxl xxl:m-auto 2xl:px-0">
      <div class="mt-8 large-layout">
        <span class="title lg:w-80">Avatar</span>
        <div class="flex mt-4 lg:mt-0">
          <div
            v-if="profile.user && profile.loaded"
            class="flex items-center justify-center rounded min-w-12 h-12 bg-primary font-family-bold"
          >
            <span
              v-if="profile.user.profileImage !== null"
              class="bg-white border border-input rounded"
            >
              <img
                :src="profile.user.profileImage"
                alt=""
                class="object-cover rounded w-12 h-12"
              />
            </span>
            <span v-else class="text-white font-family-bold">
              {{ profile.user.firstName[0] }}{{ profile.user.lastName[0] }}
            </span>
          </div>
          <p class="ml-3 lg:ml-6 text-sm">
            We accept files in PNG or JPG format, with a maximum size of 5
            MB.<br />
            <label
              for="profile-picture"
              class="text-primary cursor-pointer hover:underline img-link"
              >{{ profileRequest }}
              <input
                @change="uploadProfileImage()"
                type="file"
                name="profile-picture"
                id="profile-picture"
                class="hidden"
                accept="image/png, image/jpeg, image/jpg"
              />
            </label>
          </p>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Name</span>
          <p class="mt-2 text-sm">
            Changes cannot be made to your name after account creation.
          </p>
        </div>
        <div
          class="mt-4 lg:mt-0 sm:grid-cols-2 sm:grid sm:gap-3 lg:gap-8 max-w-xl"
        >
          <div class="flex flex-col ">
            <label class="text-xs inline-block text-sec-text title"
              >First Name</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class=" ml-4 mb-1 h-4 w-4 fill-current input-icon"
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.666687 14.6665C0.666687 13.252 1.22859 11.8955 2.22878 10.8953C3.22898 9.89507 4.58553 9.33317 6.00002 9.33317C7.41451 9.33317 8.77106 9.89507 9.77126 10.8953C10.7715 11.8955 11.3334 13.252 11.3334 14.6665H10C10 13.6056 9.57859 12.5882 8.82845 11.8381C8.0783 11.0879 7.06089 10.6665 6.00002 10.6665C4.93915 10.6665 3.92174 11.0879 3.17159 11.8381C2.42145 12.5882 2.00002 13.6056 2.00002 14.6665H0.666687ZM6.00002 8.6665C3.79002 8.6665 2.00002 6.8765 2.00002 4.6665C2.00002 2.4565 3.79002 0.666504 6.00002 0.666504C8.21002 0.666504 10 2.4565 10 4.6665C10 6.8765 8.21002 8.6665 6.00002 8.6665ZM6.00002 7.33317C7.47335 7.33317 8.66669 6.13984 8.66669 4.6665C8.66669 3.19317 7.47335 1.99984 6.00002 1.99984C4.52669 1.99984 3.33335 3.19317 3.33335 4.6665C3.33335 6.13984 4.52669 7.33317 6.00002 7.33317Z"
                  fill="#575A65"
                  fill-opacity="0.32"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-3 py-3"
                disabled
                type="text"
                name="First Name"
                autocomplete="off"
                placeholder="First name"
                :value="profile.user.firstName"
              />
            </div>
          </div>
          <div class="flex flex-col  md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Last Name</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class="mb-1 ml-4 h-4 w-4 fill-current input-icon"
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.666687 14.6665C0.666687 13.252 1.22859 11.8955 2.22878 10.8953C3.22898 9.89507 4.58553 9.33317 6.00002 9.33317C7.41451 9.33317 8.77106 9.89507 9.77126 10.8953C10.7715 11.8955 11.3334 13.252 11.3334 14.6665H10C10 13.6056 9.57859 12.5882 8.82845 11.8381C8.0783 11.0879 7.06089 10.6665 6.00002 10.6665C4.93915 10.6665 3.92174 11.0879 3.17159 11.8381C2.42145 12.5882 2.00002 13.6056 2.00002 14.6665H0.666687ZM6.00002 8.6665C3.79002 8.6665 2.00002 6.8765 2.00002 4.6665C2.00002 2.4565 3.79002 0.666504 6.00002 0.666504C8.21002 0.666504 10 2.4565 10 4.6665C10 6.8765 8.21002 8.6665 6.00002 8.6665ZM6.00002 7.33317C7.47335 7.33317 8.66669 6.13984 8.66669 4.6665C8.66669 3.19317 7.47335 1.99984 6.00002 1.99984C4.52669 1.99984 3.33335 3.19317 3.33335 4.6665C3.33335 6.13984 4.52669 7.33317 6.00002 7.33317Z"
                  fill="#575A65"
                  fill-opacity="0.32"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-3 py-3"
                disabled
                type="text"
                name="Last Name"
                autocomplete="off"
                placeholder="Last name"
                :value="profile.user.lastName"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Email</span>
          <p class="mt-2 text-sm">
            All communications and activity notifications from your account will
            be sent to your email address.
          </p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col  md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Email address</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class="mb-0.5 ml-4 h-4 w-4 fill-current input-icon"
                width="14"
                height="12"
                viewBox="0 0 14 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.99998 0H13C13.1768 0 13.3464 0.0702379 13.4714 0.195262C13.5964 0.320286 13.6666 0.489856 13.6666 0.666667V11.3333C13.6666 11.5101 13.5964 11.6797 13.4714 11.8047C13.3464 11.9298 13.1768 12 13 12H0.99998C0.823169 12 0.653599 11.9298 0.528575 11.8047C0.403551 11.6797 0.333313 11.5101 0.333313 11.3333V0.666667C0.333313 0.489856 0.403551 0.320286 0.528575 0.195262C0.653599 0.0702379 0.823169 0 0.99998 0ZM12.3333 2.82533L7.04798 7.55867L1.66665 2.81067V10.6667H12.3333V2.82533ZM2.00731 1.33333L7.04065 5.77467L12.0013 1.33333H2.00731Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-3 py-3"
                type="email"
                name="Email"
                autocomplete="off"
                placeholder="Emenike5467John@yahoo.com"
                :value="profile.user.email"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="md:w-115 lg:w-80">
          <span class="title">Phone number</span>
          <p class="mt-2 text-sm">
            All security ativities and OTP notifications from your account will
            be sent to your phone number. You will need to send a request to
            change your phone number.
          </p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col  md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Phone number</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class="mb-1 ml-4 h-4 w-4 fill-current input-icon"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.244 5.12133C4.86956 6.22032 5.77968 7.13044 6.87867 7.756L7.468 6.93067C7.56277 6.79796 7.7029 6.70459 7.86187 6.66822C8.02084 6.63186 8.18762 6.65502 8.33067 6.73333C9.27355 7.24862 10.3148 7.55852 11.386 7.64267C11.5532 7.65592 11.7092 7.73169 11.823 7.85488C11.9368 7.97807 12 8.13963 12 8.30733V11.282C12 11.4471 11.9388 11.6063 11.8282 11.7288C11.7177 11.8513 11.5655 11.9285 11.4013 11.9453C11.048 11.982 10.692 12 10.3333 12C4.62667 12 0 7.37333 0 1.66667C0 1.308 0.018 0.952 0.0546667 0.598667C0.0715031 0.434465 0.148656 0.282347 0.271193 0.171756C0.39373 0.0611648 0.552937 -3.55718e-05 0.718 1.55115e-08H3.69267C3.86037 -2.10123e-05 4.02193 0.0631677 4.14512 0.176967C4.26831 0.290767 4.34408 0.446816 4.35733 0.614C4.44148 1.68519 4.75138 2.72645 5.26667 3.66933C5.34498 3.81238 5.36814 3.97916 5.33178 4.13813C5.29541 4.2971 5.20204 4.43723 5.06933 4.532L4.244 5.12133ZM2.56267 4.68333L3.82933 3.77867C3.46986 3.00273 3.22357 2.17923 3.098 1.33333H1.34C1.336 1.444 1.334 1.55533 1.334 1.66667C1.33333 6.63733 5.36267 10.6667 10.3333 10.6667C10.4447 10.6667 10.556 10.6647 10.6667 10.66V8.902C9.82077 8.77643 8.99727 8.53014 8.22133 8.17067L7.31667 9.43733C6.95244 9.29581 6.59867 9.12873 6.258 8.93733L6.21933 8.91533C4.91172 8.17115 3.82885 7.08828 3.08467 5.78067L3.06267 5.742C2.87127 5.40133 2.70419 5.04756 2.56267 4.68333Z"
                  fill="#575A65"
                  fill-opacity="0.4"
                />
              </svg>
              <input
                id="phone-input"
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-3 py-3"
                disabled
                type="tel"
                name="Phone"
                autocomplete="off"
                placeholder=""
                :value="profile.user.phoneNumber"
              />
            </div>
            <span
              v-if="!requestPhone"
              @click="activatePhoneInput"
              class="text-title text-xs mt-3 cursor-pointer underline hover:text-primary img-link"
              >Request change of phone number.</span
            >
            <p v-show="requestPhone" class="grid">
              <span class="text-primary text-xs mt-3 underline img-link"
                >Please input phone number to continue.</span
              >
              <button
                class="btn block bg-primary mt-4 w-full md:w-72 rounded-lg h-10 text-white font-medium text-sm tracking-wider"
                @click.prevent="changePhoneNumber"
              >
                {{ isRequest ? "Requesting..." : "Change Phone" }}
              </button>
            </p>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">BVN</span>
          <p class="mt-2 text-sm">Your bank verification number</p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col  md:mt-0 mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >BVN number</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class="mb-1 ml-4 h-4 w-4 fill-current input-icon"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.3333 13.6668H0.666667C0.489856 13.6668 0.320286 13.5966 0.195262 13.4716C0.0702379 13.3465 0 13.177 0 13.0002V1.00016C0 0.823352 0.0702379 0.653782 0.195262 0.528758C0.320286 0.403734 0.489856 0.333496 0.666667 0.333496H11.3333C11.5101 0.333496 11.6797 0.403734 11.8047 0.528758C11.9298 0.653782 12 0.823352 12 1.00016V13.0002C12 13.177 11.9298 13.3465 11.8047 13.4716C11.6797 13.5966 11.5101 13.6668 11.3333 13.6668ZM10.6667 12.3335V1.66683H1.33333V12.3335H10.6667ZM3.33333 3.66683H8.66667V5.00016H3.33333V3.66683ZM3.33333 6.3335H8.66667V7.66683H3.33333V6.3335ZM3.33333 9.00016H8.66667V10.3335H3.33333V9.00016Z"
                  fill="#575A65"
                  fill-opacity="0.4"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm px-3 py-3"
                type="number"
                name="BVN"
                maxlength="11"
                autocomplete="off"
                placeholder=""
                :value="profile.user.bvn"
                disabled
              />
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8 lg:mt-25 large-layout">
        <div class="lg:w-80">
          <span class="title">Set a new password</span>
          <p class="mt-2 text-sm">
            Change your password here.
          </p>
        </div>
        <div class="mt-4 lg:mt-0 max-w-xl">
          <div class="flex flex-col  mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Old password</label
            >
            <div
              class="flex items-center w-full rounded-lg border bg-main-bg h-12"
            >
              <svg
                class="mb-1 ml-4 h-4 w-3 fill-current input-icon"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 5.66683H11.3333C11.5101 5.66683 11.6797 5.73707 11.8047 5.86209C11.9298 5.98712 12 6.15669 12 6.3335V13.0002C12 13.177 11.9298 13.3465 11.8047 13.4716C11.6797 13.5966 11.5101 13.6668 11.3333 13.6668H0.666667C0.489856 13.6668 0.320286 13.5966 0.195262 13.4716C0.0702379 13.3465 0 13.177 0 13.0002V6.3335C0 6.15669 0.0702379 5.98712 0.195262 5.86209C0.320286 5.73707 0.489856 5.66683 0.666667 5.66683H1.33333V5.00016C1.33333 4.38733 1.45404 3.78049 1.68856 3.21431C1.92308 2.64812 2.26683 2.13367 2.70017 1.70033C3.13351 1.26699 3.64796 0.923247 4.21414 0.688725C4.78033 0.454203 5.38716 0.333496 6 0.333496C6.61284 0.333496 7.21967 0.454203 7.78586 0.688725C8.35204 0.923247 8.86649 1.26699 9.29983 1.70033C9.73317 2.13367 10.0769 2.64812 10.3114 3.21431C10.546 3.78049 10.6667 4.38733 10.6667 5.00016V5.66683ZM1.33333 7.00016V12.3335H10.6667V7.00016H1.33333ZM5.33333 8.3335H6.66667V11.0002H5.33333V8.3335ZM9.33333 5.66683V5.00016C9.33333 4.11611 8.98214 3.26826 8.35702 2.64314C7.7319 2.01802 6.88406 1.66683 6 1.66683C5.11595 1.66683 4.2681 2.01802 3.64298 2.64314C3.01786 3.26826 2.66667 4.11611 2.66667 5.00016V5.66683H9.33333Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm pr-10 pl-3 py-3"
                :type="showPassword ? 'password' : 'text'"
                required
                autocomplete="off"
                placeholder="••••••••"
                v-model="passwordBody.oldPassword"
                id="oldPass"
              />
              <span
                ref="passwordShow"
                @click.prevent="showPassword = !showPassword"
                class="cursor-pointer text-xs text-sec-text -ml-10 pr-2 z-50 input-icon"
              >
                {{ showPassword ? "Show" : "Hide" }}
              </span>
            </div>
          </div>
          <div class="flex flex-col  mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >New password</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class="mb-1 ml-4 h-4 w-3 fill-current input-icon"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 5.66683H11.3333C11.5101 5.66683 11.6797 5.73707 11.8047 5.86209C11.9298 5.98712 12 6.15669 12 6.3335V13.0002C12 13.177 11.9298 13.3465 11.8047 13.4716C11.6797 13.5966 11.5101 13.6668 11.3333 13.6668H0.666667C0.489856 13.6668 0.320286 13.5966 0.195262 13.4716C0.0702379 13.3465 0 13.177 0 13.0002V6.3335C0 6.15669 0.0702379 5.98712 0.195262 5.86209C0.320286 5.73707 0.489856 5.66683 0.666667 5.66683H1.33333V5.00016C1.33333 4.38733 1.45404 3.78049 1.68856 3.21431C1.92308 2.64812 2.26683 2.13367 2.70017 1.70033C3.13351 1.26699 3.64796 0.923247 4.21414 0.688725C4.78033 0.454203 5.38716 0.333496 6 0.333496C6.61284 0.333496 7.21967 0.454203 7.78586 0.688725C8.35204 0.923247 8.86649 1.26699 9.29983 1.70033C9.73317 2.13367 10.0769 2.64812 10.3114 3.21431C10.546 3.78049 10.6667 4.38733 10.6667 5.00016V5.66683ZM1.33333 7.00016V12.3335H10.6667V7.00016H1.33333ZM5.33333 8.3335H6.66667V11.0002H5.33333V8.3335ZM9.33333 5.66683V5.00016C9.33333 4.11611 8.98214 3.26826 8.35702 2.64314C7.7319 2.01802 6.88406 1.66683 6 1.66683C5.11595 1.66683 4.2681 2.01802 3.64298 2.64314C3.01786 3.26826 2.66667 4.11611 2.66667 5.00016V5.66683H9.33333Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm pr-10 pl-3 py-3"
                :type="showNewPassword ? 'password' : 'text'"
                required
                autocomplete="off"
                placeholder="••••••••"
                v-model="passwordBody.newPassword"
                id="newPass"
                name="newPass"
              />
              <span
                ref="passwordShow"
                @click.prevent="showNewPassword = !showNewPassword"
                class="cursor-pointer text-xs text-sec-text -ml-10 pr-2 z-50 input-icon"
              >
                {{ showNewPassword ? "Show" : "Hide" }}
              </span>
            </div>
          </div>
          <div class="flex flex-col  mt-4">
            <label class="text-xs inline-block text-sec-text title"
              >Confirm password</label
            >
            <div
              class="flex items-center w-full border rounded-lg bg-main-bg h-12"
            >
              <svg
                class="mb-1 ml-4 h-4 w-3 fill-current input-icon"
                width="12"
                height="14"
                viewBox="0 0 12 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6667 5.66683H11.3333C11.5101 5.66683 11.6797 5.73707 11.8047 5.86209C11.9298 5.98712 12 6.15669 12 6.3335V13.0002C12 13.177 11.9298 13.3465 11.8047 13.4716C11.6797 13.5966 11.5101 13.6668 11.3333 13.6668H0.666667C0.489856 13.6668 0.320286 13.5966 0.195262 13.4716C0.0702379 13.3465 0 13.177 0 13.0002V6.3335C0 6.15669 0.0702379 5.98712 0.195262 5.86209C0.320286 5.73707 0.489856 5.66683 0.666667 5.66683H1.33333V5.00016C1.33333 4.38733 1.45404 3.78049 1.68856 3.21431C1.92308 2.64812 2.26683 2.13367 2.70017 1.70033C3.13351 1.26699 3.64796 0.923247 4.21414 0.688725C4.78033 0.454203 5.38716 0.333496 6 0.333496C6.61284 0.333496 7.21967 0.454203 7.78586 0.688725C8.35204 0.923247 8.86649 1.26699 9.29983 1.70033C9.73317 2.13367 10.0769 2.64812 10.3114 3.21431C10.546 3.78049 10.6667 4.38733 10.6667 5.00016V5.66683ZM1.33333 7.00016V12.3335H10.6667V7.00016H1.33333ZM5.33333 8.3335H6.66667V11.0002H5.33333V8.3335ZM9.33333 5.66683V5.00016C9.33333 4.11611 8.98214 3.26826 8.35702 2.64314C7.7319 2.01802 6.88406 1.66683 6 1.66683C5.11595 1.66683 4.2681 2.01802 3.64298 2.64314C3.01786 3.26826 2.66667 4.11611 2.66667 5.00016V5.66683H9.33333Z"
                  fill="#B5B5B5"
                />
              </svg>
              <input
                class="bg-transparent w-full h-full text-title outline-none rounded-lg text-sm pr-10 pl-3 py-3"
                :type="showConfirmNewPassword ? 'password' : 'text'"
                required
                autocomplete="off"
                placeholder="••••••••"
                v-model="passwordBody.confirmPassword"
                id="confirmPass"
                name="confirmPass"
                @keyup="checkPassMatch"
              />
              <span
                ref="passwordShow"
                @click.prevent="
                  showConfirmNewPassword = !showConfirmNewPassword
                "
                class="cursor-pointer text-xs text-sec-text -ml-10 pr-2 z-50 input-icon"
              >
                {{ showConfirmNewPassword ? "Show" : "Hide" }}
              </span>
            </div>
            <span ref="validate" class="text-red-400 text-sm mt-1 title"></span>
          </div>
        </div>
      </div>

      <div class="large-layout">
        <button
          class="lg:col-start-2 my-13.5 btn flex items-center justify-center bg-primary w-full rounded-lg h-12 hover:bg-bg-hover text-white font-medium text-sm tracking-wider max-w-xl"
          @click.prevent="changeUserPassword"
          :isDisabled="isRequest === true"
        >
          {{ isRequest ? "Loading..." : "Change Password" }}
        </button>
      </div>

      <div
        v-if="getOtp"
        @click.self="getOtp = false"
        class="fixed bg-search-bg left-0 top-0 h-screen w-full flex justify-center overflow-y-scroll px-3 md:px-0"
      >
        <div class="max-w-lg mb-auto mx-auto mt-12 px-3 md:px-0">
          <div
            class="max-w-xl px-3 pb-8 bg-white sm:border border-border sm:px-20 sm:shadow-loan rounded-lg mx-auto sm:py-12"
          >
            <h3
              class="pt-6 font-medium text-sm text-center text-title verify--title"
            >
              Verify your phone number
            </h3>
            <p class="text-sm text-sec-text text-center py-3 verify--text">
              Please enter the code sent to
              <!-- <span class="text-secondary">(+234) 802 345 678</span> -->
              <span class="text-primary">{{}}</span>
            </p>
            <form @submit.prevent="goTo" class="mt-8">
              <div class="flex items-center justify-center ">
                <v-otp-input
                  ref="otpInput"
                  input-classes="otp-input "
                  separator=" "
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOnChange"
                  @on-complete="handleOnComplete"
                />
              </div>
              <!-- <p
                v-if="timeLeft > 0"
                class="my-6 text-textColor text-sm text-center"
              >
                If you don’t recieve a code in
                <span class="text-primary font-bold">{{ formattedTimeLeft }}</span>
              </p> -->
              <template v-if="timeLeft === 0">
                <p class="my-6 text-textColor text-sm text-center">
                  Click the button below to resend
                </p>
                <div class="flex justify-center">
                  <a
                    @click.prevent="resend"
                    to="send"
                    class="resend mb-6 text-primary border border-border font-medium py-2 px-3 rounded-lg m-auto inline-block"
                  >
                    Resend
                  </a>
                </div>
              </template>
              <div v-if="isRequest" class="mt-6 flex justify-center w-full">
                <span class="animate-spin">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 0C13.6228 0 13.3171 0.305746 13.3171 0.682927V7.51219C13.3171 7.88934 13.6228 8.19512 14 8.19512C14.3772 8.19512 14.6829 7.88934 14.6829 7.51219V0.682927C14.6829 0.305746 14.3772 0 14 0ZM4.58841 3.90549C4.41362 3.90549 4.2309 3.96421 4.09756 4.09756C3.83084 4.36427 3.83084 4.80188 4.09756 5.0686L8.93139 9.89177C9.19807 10.1584 9.62503 10.1585 9.89178 9.89177C10.1585 9.62506 10.1584 9.19808 9.89178 8.9314L5.06861 4.09756C4.93527 3.96421 4.76321 3.90549 4.58841 3.90549ZM23.4116 3.90549C23.2368 3.90549 23.0648 3.96421 22.9314 4.09756L18.1082 8.9314C17.8416 9.19809 17.8416 9.62507 18.1082 9.89177C18.3749 10.1585 18.8019 10.1585 19.0686 9.89177L23.9024 5.0686C24.1692 4.80189 24.1691 4.36426 23.9024 4.09756C23.7691 3.96421 23.5863 3.90549 23.4116 3.90549ZM0.682927 13.3171C0.305746 13.3171 0 13.6228 0 14C0 14.3772 0.305746 14.6829 0.682927 14.6829H7.51219C7.88934 14.6829 8.19512 14.3772 8.19512 14C8.19512 13.6228 7.88934 13.3171 7.51219 13.3171H0.682927ZM20.4878 13.3171C20.1107 13.3171 19.8049 13.6228 19.8049 14C19.8049 14.3772 20.1107 14.6829 20.4878 14.6829H27.3171C27.6943 14.6829 28 14.3772 28 14C28 13.6228 27.6943 13.3171 27.3171 13.3171H20.4878ZM9.41158 17.9055C9.23682 17.9055 9.06473 17.9749 8.93139 18.1082L4.09756 22.9314C3.83084 23.1981 3.83088 23.6357 4.09756 23.9024C4.36428 24.1692 4.80186 24.1692 5.06861 23.9024L9.89178 19.0686C10.1584 18.8019 10.1584 18.3749 9.89178 18.1082C9.75841 17.9749 9.58635 17.9055 9.41158 17.9055ZM18.5884 17.9055C18.4136 17.9055 18.2416 17.9749 18.1082 18.1082C17.8415 18.3749 17.8416 18.8019 18.1082 19.0686L22.9314 23.9024C23.1981 24.1692 23.6358 24.1692 23.9024 23.9024C24.1692 23.6357 24.1692 23.1981 23.9024 22.9314L19.0686 18.1082C18.9353 17.9749 18.7632 17.9055 18.5884 17.9055ZM14 19.8049C13.6228 19.8049 13.3171 20.1107 13.3171 20.4878V27.3171C13.3171 27.6943 13.6228 28 14 28C14.3772 28 14.6829 27.6943 14.6829 27.3171V20.4878C14.6829 20.1107 14.3772 19.8049 14 19.8049Z"
                      fill="#1656C1"
                      fill-opacity="0.7"
                    />
                  </svg>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { BASE_API } from "../../constants";
import axios from "axios";
import { ApiInstance as api } from "../../utils";
import Settings from "../dashboard/Settings";

export default {
  name: "PersonalSettings",
  components: {
    Settings,
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
      verificationTTL: (state) => state.ttl,
    }),
    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      const minutes = Math.floor(timeLeft / 60);
      let seconds = timeLeft % 60;
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      return `${minutes}:${seconds}`;
    },
    timeLeft() {
      return this.verificationTTL - this.timePassed;
    },
    timeFraction() {
      const rawTimeFraction = this.timeLeft / this.verificationTTL;
      return (
        rawTimeFraction - (1 / this.verificationTTL) * (1 - rawTimeFraction)
      );
    },
  },
  data() {
    return {
      showPassword: true,
      showNewPassword: true,
      showConfirmNewPassword: true,
      isRequest: false,
      spinner: "",
      profileImage: "",
      profileRequest: "Change my picture",
      requestPhone: false,
      numberToChange: "",
      getOtp: false,
      timePassed: 0,
      timerInterval: null,
      passwordBody: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    checkPassMatch() {
      if (this.passwordBody.newPassword !== this.passwordBody.confirmPassword) {
        this.$refs.validate.innerHTML = "Password does not match";
      } else {
        this.$refs.validate.innerHTML = "";
      }
    },
    uploadProfileImage() {
      this.profileRequest = "Uploading image...";
      const img = document.getElementById("profile-picture");
      // console.log(img.files)
      let file = img.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        let base64File;
        base64File = reader.result;
        console.log(base64File);
        this.profileImage = reader.result;

        axios
          .post(BASE_API + "/auth/profile/image/update/", {
            id: this.profile.user.id,
            imageData: this.profileImage,
          })
          .then((res) => {
            if (res.data.statusCode === 201) {
              this.$notie.alert({
                text: `Image upload successful.`,
                type: "success",
                time: 3,
                position: "top",
              });
            }
            this.profileRequest = "Change my picture";
          })
          .catch((e) => {
            this.$notie.alert({
              text: `Image upload failed. Please try again.`,
              type: "error",
              time: 3,
              position: "top",
            });
            this.profileRequest = "Change my picture";
            return e;
          });
      };
      reader.readAsDataURL(file);
    },
    changeUserPassword() {
      if (this.passwordBody.newPassword !== this.passwordBody.confirmPassword) {
        return this.$notie.alert({
          text: `Passwords do not match.`,
          type: "error",
          time: 3,
          position: "top",
        });
      }
      this.isRequest = true;
      api
        .post("/auth/change/password", {
          id: this.profile.user.id,
          ...this.passwordBody,
        })
        .then((res) => {
          if (res.data.statusCode > 199 && res.data.statusCode < 299) {
            console.log(res.data);
            this.isRequest = false;
            this.$notie.alert({
              text: res.data.message,
              type: "success",
              time: 3,
              position: "top",
            });
            setTimeout(() => {
              // window.location.reload();
              document.getElementById("oldPass").value = "";
              document.getElementById("newPass").value = "";
              document.getElementById("confirmPass").value = "";
            }, 3200);
          }
        })
        .catch((e) => {
          this.$notie.alert({
            text: `Password change unsuccessful.`,
            type: "error",
            time: 3,
            position: "top",
          });
          this.isRequest = false;
          return e;
        });
    },
    activatePhoneInput() {
      this.requestPhone = true;
      const phoneInput = document.getElementById("phone-input");
      phoneInput.removeAttribute("disabled");
      phoneInput.focus();
    },
    changePhoneNumber() {
      this.isRequest = true;
      this.numberToChange = document.getElementById("phone-input").value;
      api
        .post("/auth/change/phone/initiate/", {
          id: this.profile.user.id,
          phoneNumber: document.getElementById("phone-input").value,
        })
        .then((res) => {
          this.isRequest = false;
          if (res.data.statusCode > 199 && res.data.statusCode < 299) {
            if (res.data.message.includes("remaining")) {
              this.$notie.alert({
                text: res.data.message,
                type: "success",
                time: 15,
                position: "top",
              });
            }
            this.$notie.alert({
              text: res.data.message,
              type: "success",
              time: 3,
              position: "top",
            });
          }
          this.getOtp = true;
          this.startTimer();
        });
    },
    onTimesUp() {
      clearInterval(this.timerInterval);
    },
    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed += 1), 1000);
    },
    handleOnComplete(value) {
      this.isRequest = true;
      api
        .post("/auth/change/phone/complete/", {
          userId: this.profile.user.id,
          code: value,
        })
        .then((res) => {
          if (res.data.statusCode > 199 && res.data.statusCode < 299) {
            this.isRequest = false;
            this.$notie.alert({
              text: res.data.message,
              type: "success",
              time: 3,
              position: "top",
            });
            this.getOtp = false;
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((e) => {
          this.$notie.alert({
            text: `Phone change unsuccessful.`,
            type: "error",
            time: 3,
            position: "top",
          });
          this.isRequest = false;
          this.getOtp = false;
          return e;
        });
    },
    handleOnChange() {},
  },
};
</script>

<style lang="scss" scope>
.title,
.img-link {
  font-family: SohneKraftig, sans-serif;
}
.large-layout {
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 440px 1fr;
  }
}
.verify--register,
.whatsapp {
  font-family: SohneKraftig, sans-serif;
  font-size: 0.875rem;
}

.verify--title {
  font-family: SohneHalbfett, sans-serif;
  font-size: 1.5rem;
}

.verify--text {
  font-size: 0.875rem;
  width: 200px;
  margin: auto;
}

.resend {
  width: 109px;
  text-align: center;
}

// . {
//   div {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//     max-width: 380px;
//   }
// }

.otp-input {
  width: 10vw;
  height: 10vw;
  max-width: 48px;
  max-height: 48px;
  margin: 0 4px;
  background: #f9fafc;
  border: 1px solid rgba(94, 81, 81, 0.04);
  border-radius: 8px;
  text-align: center;
  color: #575a65;
  font-size: 1.25rem;
  font-family: SohneKraftig, sans-serif;
}

.otp-input:focus {
  border: 1px solid #1656c1;
  outline: none;
}

.otp-input::placeholder {
  content: "0";
  color: #a8b3d6;
}
</style>
